import React from 'react'
import Logo from "../../assets/icon.svg"

export const Footer = () => {
  return (
    <footer className="flex flex-col py-2 text-white">
      {/* <div className="flex justify-center mb-3">
        <Logo className="inline-block h-6 w-6" width="24" height="24" />
      </div> */}
      <div className="flex justify-center">
        <small className="text-grey-dark">Gjord med <span>💚</span>i Norrland © Vi {new Date().getFullYear()}</small>
      </div>
    </footer>
  )
}
