import React from "react"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

import Logo from "../../assets/icon.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Kommer snart" keywords={[`rental`, `rent`, `hyr`, `hyr ut`]} />
    <div className="flex justify-center items-center h-screen">
      <div>
        <Logo className="h-300 w-300" width="300" height="300" />
        <p className="text-white text-center">
          <a href="mailto:hello@vihyr.com" target="_top">hello@vihyr.com</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
