import React from "react"
import { StaticQuery, graphql } from "gatsby"
/* import { Header } from "./header" */
import { Footer } from "./footer"

import "../styles/main.css"

export const Layout = ({ children }: { children: any }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className="container mx-auto h-screen">
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <main>{children}</main>
        <Footer />
      </div>
    )}
  />
)